<template>
  <div class="course-unit__wrapper" v-if="blockURL !== ''">
    <iframe
      width="100%"
      :src="src"
      title="Course Unit"
      ref="blockframe"
      frameBorder="0"
      @load="adjustIframe"
      scrolling="no"
    ></iframe>
  </div>
  <div class="sp-main__empty-state" v-else>
    <img
      src="@/assets/images/not-found/no-unit.svg"
      alt="no unit"
      width="auto"
      height="auto"
    />
    <p>{{ $t("vle.unit.empty.message") }}</p>
  </div>
</template>
<script>
/**
 * As per Open edX course hierarchy, a unit the smallest component where the learning experience is
 * added by the content creators.
 *
 * Technically, the unit is served by the LMS web application which is using `Session` based
 * authentication. From the Single Page Application (SPA) we exchange the session cookie for
 * Bearer token and use `iframe` to set the cookies and load the web url.
 *
 * This is what this component does:
 * 1. Get the `block` or `unit` URL from props
 * 2. Render the iframe with loading text.
 * 3. Check if the session cookie already obtained by checking the store value.
 * 4. If the session cookie is present and is still valid then set the cookies
 *    in an iframe and load the `block` URL.
 * 5. Else make an `Ajax` call to the API to obtain a session cookie and save the
 *    session cookie in the store and set the cookies in the iframe and load the
 *    `block` URL.
 *
 * Reference Links:
 * 1. https://github.com/edx/frontend-app-learning/blob/2f738fdba4b775d94b99d11af36956e954f6001c/docs/decisions/0002-courseware-page-decisions.md
 */

import { mapGetters } from "vuex";
export default {
  props: {
    blockURL: {
      type: String
    }
  },
  data() {
    return {
      src: "",
      previousIframeHeight: null,
      presentIframeHeight: null,
      iframeHeightIntervalId: null
    };
  },
  computed: {
    ...mapGetters([
      "getSessionCookie",
      "isSessionCookieExists",
      "allConfig",
      "scormStatus",
      "isScormUnit"
    ])
  },
  watch: {
    blockURL(newValue, oldValue) {
      if (oldValue !== newValue) {
        this.src = "";
        setTimeout(() => {
          this.init();
        }, 300);
      }
    },
    getSessionCookie(newValue, oldValue) {
      if (oldValue !== newValue) {
        this.addCookieAndLoadPage();
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
    this.addMessageEventListener();
  },
  beforeDestroy() {
    this.removeMessageEventListener();
    this.clearIframeHeightIntervalId();
  },
  methods: {
    clearIframeHeightIntervalId() {
      if (this.iframeHeightIntervalId) {
        clearInterval(this.iframeHeightIntervalId);
        this.previousIframeHeight = null;
        this.presentIframeHeight = null;
      }
    },
    addMessageEventListener() {
      // This event listener is added to fix the 404 session cookie
      // issue, from open edx `xblock` view, if it is 404 we'll postMessage
      // `xblock_404`
      window.addEventListener("message", this.messageEventHandler, false);
    },
    removeMessageEventListener() {
      window.removeEventListener("message", this.messageEventHandler, false);
    },
    messageEventHandler(event) {
      if (event.origin !== location.origin) {
        return false;
      }
      if (event.data === "xblock_404") {
        // By passing the second param (addNoCacheHeader) to `true`
        // so we can get the fresh cookie ( not from cache ), this flag
        // is added since in some scenarios we found the expired session
        // cookie is still present in back-end cache
        this.$store.dispatch("exchangeSessionCookie", true);
      }
    },
    init() {
      if (this.$refs.blockframe) {
        this.$store.commit("SET_IFRAME_CONTENT_STATUS", false);
        this.$refs.blockframe.contentDocument.body.innerHTML = `<div style="display:flex; align-items:center; justify-content:center; height:100%;">${this.$t(
          "general.loading_ellipsis"
        )}...</div>`;
        // Check if the session cookie is already exists
        if (this.isSessionCookieExists) {
          this.addCookieAndLoadPage();
        } else {
          this.$store.dispatch("exchangeSessionCookie");
        }
      }
    },
    addCookieAndLoadPage() {
      this.getSessionCookie.forEach(cookie => {
        // Remove `HttpOnly` flag to set progrmatically
        // and set to the iframe
        cookie = cookie.replace("HttpOnly;", "");
        cookie = cookie.replace(
          `Domain=${this.allConfig.lmsCookieBaseDomain};`,
          ""
        );
        this.$refs.blockframe.contentDocument.cookie = cookie;
      });
      setTimeout(() => {
        this.src = this.blockURL;
      }, 300);
    },
    adjustIframe() {
      const iframe = this.$refs.blockframe;
      this.$store.commit("SET_IFRAME_CONTENT_STATUS", false);
      iframe.addEventListener("load", () => {
        // TODO:: temporary fix to avoid some SCORM content hide in the top by
        // adding 24px scrollTop, need to figure out why
        if (
          document.getElementById("ul-content__main") &&
          document.getElementById("ul-content__main").scrollTop > 0
        ) {
          document.getElementById("ul-content__main").scrollTop = 0;
        }

        // clear any previous interval
        this.clearIframeHeightIntervalId();

        const doc = iframe.contentDocument;

        if (doc && doc.body) {
          let view_content = iframe.contentWindow.document.getElementsByClassName(
            "view-in-course"
          );
          if (view_content.length > 0) {
            // For the SCOM packages, we use a different layout with a full screen view
            // So, no need to adjust the height dynamically
            if (!this.isScormUnit) {
              iframe.style.minHeight = doc.body.scrollHeight + "px";
              iframe.style.height = doc.body.scrollHeight + "px";

              /**
                This below lineS of code is added to monitor the dynamic height changes inside the iframe
                Due to the new design update, we have to place the iframe inside container and during
                the initial setup we setup the height of iframe conten to the container height but 
                when the iframe grows, the parent container doesn't know the dynamic height changes,
                Hence, we need to monitor periodically and update the height of the iframe.
                Without this change, when the iframe height changes, some of the content is not visible.

                Other alternative approaches considered
                1. Use the post message event and communicate the updated height to parent
                  - This method involves editing the open edX native xBlock source code and 
                    need to update for all the xBlocks hence not feasible.
                2. Use of on click method handler
                  - Using this method, the user may need to perform additional click after the height changes.
                3. requestAnimationFrame - to check and update the height before the next browser repaint
                  - Since this will be ideal solution for animation, for this case, the code will be executed 60 times
                    per second(60fps screen), which will easily fillup the browser stack and I don't think it is ideal for this.
                
                Finally, I ended up with using `setInternal` which is used for animating content.
                
                TODO::Still looking for better way to do this.

                Bug ID: https://extranet.who.int/jira/browse/WALP-569
              */
              this.iframeHeightIntervalId = setInterval(() => {
                this.handleIframeDynamicHeight();
              }, 300);
            } else {
              // reset previous iframe height settings
              iframe.style.minHeight = "100%";
              iframe.style.height = "100%";
            }
          }
        }
        doc.head.insertAdjacentHTML(
          "beforeend",
          `<style>.content-wrapper { margin: 0;}.scorm_button,.cmi5_button{display:none} .openedxscorm_block .full-screen-scorm .scorm_object,.openedxcmi5_block .full-screen-cmi5 .cmi5_object {top: 0; height: 100%;}</style>`
        );
        // to allow DOM to update
        setTimeout(() => {
          this.$store.commit("SET_IFRAME_CONTENT_STATUS", true);
        }, 0);
      });
    },
    handleIframeDynamicHeight() {
      const iframeRef = this.$refs.blockframe;
      const doc =
        iframeRef?.contentDocument || iframeRef?.contentWindow?.document;
      const scrollHeight = doc?.body?.scrollHeight;
      if (scrollHeight) {
        this.presentIframeHeight = scrollHeight;
        if (this.presentIframeHeight !== this.previousIframeHeight) {
          iframeRef.style.minHeight = this.presentIframeHeight + "px";
          iframeRef.style.height = this.presentIframeHeight + "px";

          this.previousIframeHeight = this.presentIframeHeight;
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.course-unit__wrapper {
  height: 100%;

  iframe {
    display: block;
    height: 100%;
    transition: min-height 0.5s, height 0.5s;
    box-shadow: none;
  }
}
.sp-main__empty-state {
  height: inherit;
  background: #fff;
  @include center;
}
</style>
