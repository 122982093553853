<template>
  <div class="xblock-wrapper">
    <Loader v-if="!iframeContentStatus" />
    <CourseUnit
      :style="iframeContentStatus ? 'opacity:1' : 'opacity:0'"
      :blockURL="blockURL"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CourseUnit from "@/components/SelfPaced/CourseUnit.vue";
export default {
  components: { CourseUnit },
  computed: {
    ...mapGetters(["iframeContentStatus"]),
    blockURL() {
      return this.$route.params.id !== ""
        ? `/lms-xblock/${this.$route.params.id}`
        : "";
    }
  }
};
</script>
<style lang="scss" scoped>
.xblock-wrapper {
  height: 100%;
  width: 100%;
  // To fix Loader horizontal scroll
  // TODO:: Fix the horizontal scroll in Loader
  overflow-x: hidden;

  .course-unit__wrapper {
    height: inherit;
    overflow: auto;
  }
}
</style>
